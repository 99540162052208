import React, { useState, useEffect } from 'react';
import { Typography, TextField, Select, MenuItem, Button, Slider, Box, ToggleButton, ToggleButtonGroup, Checkbox, FormGroup, FormControlLabel, IconButton } from "@mui/material";
import { useQuery, useMutation, gql } from "@apollo/client";
//import Container from 'react-bootstrap/Container';
import Grid from '@mui/material/Grid';
import Taglist from '../components/Taglist';
import Texteditor from '../components/Texteditor';
import Uploadfp from '../components/Uploadfp';
import Sharewithwhom from '../components/Sharewithwhom';
import GetSignedFileUrl from '../components/GetSignedFileUrl';
import Fab from '@mui/material/Fab';
import sortArray from 'sort-array';
import { NavLink, useSubmit } from "react-router-dom";
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "../components/formatlinks.css";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { yellow, red, green } from '@mui/material/colors';
import {v4 as uuidv4} from 'uuid';

//import Taglist from './Taglist';

const mutatequery = gql`
  mutation addpost($title: String, $posttext: String, $author: String, $authoremail: String, $otherauthors: [String], $file: [FileInput], $resourcelink: [ResourceLinkInput], $tags: [String], $posttype: [String], $blog: String, $accessgroups: [String], $position: Int) {
    newpost(title: $title, posttext: $posttext, author: $author, authoremail: $authoremail, otherauthors: $otherauthors, file: $file, resourcelink: $resourcelink, tags: $tags, posttype: $posttype, blog: $blog, accessgroups: $accessgroups, position: $position) {
      author
      posttext
    }
  }`
;

const resourcequery = gql`
  query resources {
    getpostscats(accessgroups: ["staff","faculty"], posttype: ["resource"]) {
     id
     title
     author
     authoremail
     posttext
     position
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     accessgroups
     blog
     otherauthors
     posttype
     tags
     createdAt
  }
 }
`;

function Addresource() {
  //let idnumber = sessionStorage.getItem("otherinfo")*1;
  const [ addpost, { data2, loading2, error2 } ] = useMutation(mutatequery, { refetchQueries: [{ query: resourcequery }],});
  const [ title, setTitle] = useState();
  const [ filenames, setFileNames] = useState([]);
  const [ addfiles, setAddFiles] = useState(false);
  const [ seltags, setSelTags ] = useState(null);
  const [ newtag, setNewTag] = useState();
  const [ sharearray, setShareArray] = useState();
  const [ posttext, setPostText] = useState();
  const [ position, setPosition] = useState(3);
  const activeStyle = { color: 'green' };
  const [ gotolink, setGoToLink] = useState("/addresource");
  const [ shownewlink, setShowNewLink] = useState(false);
  const [ resourcelinks, setResourceLinks] = useState([{resourcelinkname: '',resourcelinkurl:''}]);
  //const [ rlinks, setRLinks] = useState([{resourcelinkid: uuidv4(), resourcelinkname: '', resourcelinkurl:''}]);
  const [ rlinks, setRLinks] = useState([]);
  const [ numrows, setNumRows ] = useState(1);
  const [ readytosave, setReadyToSave ] = useState(false);
  const [ dataentered, setDataEntered ] = useState({title: false, posttext: false, tags: false, accessgroups: false});

  const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();

  const handleBlogSubmit = (e) => {
    e.preventDefault();
    console.log(e.target["title"].value);
  }

  const handleBlogSelect = (e, value) => {
    console.log(e.target.value);
    setBlogsel(e.target.value);
  }

  const handleTitleChange = (e, value) => {
    setTitle(e.target.value);
    if (e.target.value.length>0) setDataEntered({ ...dataentered, title: true});
    else setDataEntered({ ...dataentered, title: false});
  }

  const handleChangePosition = (event, value) => {
    setPosition(value);
  };

  const addlink = (event, value) => {
    setRLinks([...rlinks, { resourcelinkid: uuidv4(), resourcelinkname: '',resourcelinkurl:'' }]);
  };

  const getposttext = (data) => {
    //console.log(data);
    //console.log("userinfo=", userinfo);
    setPostText(data);
    if (data && data.length>8) setDataEntered({ ...dataentered, posttext: true});
    else setDataEntered({ ...dataentered, posttext: false});
  }

  const getfilenames = (data) => {
    setFileNames(data);
  }

  const gettags = (data) => {
    console.log("tags=", data);
    setSelTags(data);
    if (data && data.length>0) setDataEntered({ ...dataentered, tags: true});
    else setDataEntered({ ...dataentered, tags: false});
  }

  const getnewtag = (data) => {
    setNewTag(data);
  }

  const getsharearray = (data) => {
    setShareArray(data);
    if (data && data.length>0) setDataEntered({ ...dataentered, accessgroups: true});
    else setDataEntered({ ...dataentered, accessgroups: false});
  }

  const deleteresource = (resourcelinkid) => {
    setRLinks(rlinks.filter((rl) => rl.resourcelinkid!==resourcelinkid));
  };  

  const handleUpdateItem = (resourcelinkid, updatedItem) => {
    // Update the item in the array
    setRLinks(prevItems => prevItems.map(item =>
      item.resourcelinkid === resourcelinkid ? updatedItem : item
    ));
  };

  // save all of the information for the post
  const savepost = () => {
    let author = userinfo.name;
    let authoremail = userinfo.email;
    let file = filenames;
    let resourcelink = rlinks;
    let posttype = ["resource"];
    let accessgroups = sharearray;
    let tags = seltags;

    console.log("file=", file);
    console.log("resourcelink=", resourcelink);
    console.log("tags=", tags);

    addpost({  variables: { title, posttext, author, authoremail, file, resourcelink, tags, posttype, accessgroups, position } });
    //addpost({  variables: { title, posttext, author, authoremail, file, tags, posttype, accessgroups, position } });

    navigate("/getresources", { replace: true})
  }

  useEffect(() => {
    //console.log(sharearray);
  }, [sharearray]);

  useEffect(() => {
    console.log("rlinks=", rlinks);
  }, [rlinks]);

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  useEffect(() => {
    //console.log("resourcelinks=", resourcelinks);
  }, [resourcelinks]);

  useEffect(() => {
    console.log("dataentered=", dataentered);
    if (dataentered.title && dataentered.posttext && dataentered.tags && dataentered.accessgroups) setReadyToSave(true);
    else setReadyToSave(false);
  }, [dataentered]);

  return (
    <div align="left">

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '600px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/getresources">View Resources</ToggleButton>
          <ToggleButton value="/addresource">Add Resource</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h4">Add Resource</Typography>
      </Stack>

    <Grid container>
      <Grid item style={{ margin: "10px" }}>
          <TextField name="title" value={title} onChange={handleTitleChange} placeholder="Enter a title for your post." variant="standard" size="large" sx={{ m: 1, width: 580 }}></TextField><br/>
          <Texteditor func={getposttext} height="500px" width="550px" sx={{ m: 2}}/><br/>
      </Grid>
      <Grid item sx={{ m: 5 }}>
          <Taglist functags={gettags} funcnewtag={getnewtag} width={"375px"} /><br/><br/>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between' }}>

            <Box sx={{ m: 1, fontWeight: 500 }}>{"Position: " + position}</Box>
            <Slider
              aria-label="Temperature"
              defaultValue={3}
              value={position}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={5}
              onChange={handleChangePosition}
              sx={{width: 200}}
            />
            <Box sx={{ fontSize: 11, ml: 6, display:'flex', alignItems:'center' }}>(Resources with lower numbers appear first.)</Box>


          </Stack><br/>

          <Sharewithwhom funcsharearray={getsharearray}/>

          <Box sx={{ ml: 1, mt: 5, fontWeight: 500 }}>Add Files</Box>
          <Uploadfp func={getfilenames}/>

          <Box sx={{ ml: 1, mt: 5, fontWeight: 500 }}>Add Links</Box>
          <Box sx={{bgcolor: '#f2f0f0'}}>
          { rlinks.map((rl, index) => (
              <>
                 <TextField name={"linkname" + index} value={rl.resourcelinkname} onChange={(e) => handleUpdateItem(rl.resourcelinkid, { ...rl, resourcelinkname: e.target.value })} placeholder="Enter name" variant="standard" sx={{ m: 1, width: 225, fontSize: 10 }}></TextField>
                 <TextField name={"linkurl" + index} value={rl.resourcelinkurl} onChange={(e) => handleUpdateItem(rl.resourcelinkid, { ...rl, resourcelinkurl: e.target.value })} placeholder="Enter link" variant="standard" sx={{ m: 1, width: 275, fontSize: 10 }}></TextField>
                 <IconButton name={"deleteresource" + index} sx={{ color: red[500], width: 20, height: 20, ml: 1, mt: 2, mr: 1 }} onClick={() => deleteresource(rl.resourcelinkid) }><DeleteIcon /></IconButton><br/>
              </>
            ))
          }

          <IconButton sx={{ color: green[500], width: 20, height: 20, ml: 1, mb: 2, mt: 1 }} onClick={addlink}><AddCircleIcon /></IconButton>
            <br/>
          </Box>

          <br/>
          <Button onClick={savepost} variant='contained' disabled={!readytosave}>Save Resource</Button>
          {!readytosave && (
           <Typography sx={{ fontSize: 11, width:350 }} >You must enter a title, description, tags, and select with whom to share this resource, before saving it.</Typography>
          )}


      </Grid>

      <Grid item>

      </Grid>

    </Grid>

    </div>
  );
}

export default Addresource;

import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
//import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
//import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import Avatar from '@mui/material/Avatar';
import { styled } from "@mui/material";
import { theme } from "./Theme";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

const Search = styled("div")(({ theme }) => ({
  backgroundColor: "#f3f5fb",
  padding: "0 2px",
  borderRadius: theme.shape.borderRadius,
  width: "100%",
  position: "relative",
  "&:hover": {
    backgroundColor: theme.palette.common.blue,
  },
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing.unit * 3,
    width: "auto",
  },
}));

const styles = {
  root: {
    width: "100%",
    marginBottom: "10px",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  tabContainer: {
    marginLeft: "auto",
  },
  desktopContainer: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  mobileContainer: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
};

const Header = (props) => {
  const orgunit = props.orgunit;
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [ pages, setPages] = useState([]);

  let userinfo = useSelector((state) => state.userinfo);
  const userorgunit = useSelector((state) => state.userorgunit);
  const user = JSON.parse(sessionStorage.getItem("user"));
  if (userinfo.length===0) {
    userinfo = Object.assign({}, user);
    //console.log("userinfo=", user);
  }
  //const orgunit = sessionStorage.getItem("orgunit");

  sessionStorage.setItem('headershow', 'Y');

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  useEffect(() => {
    //let orgunit = sessionStorage.getItem("orgunit");
    if (orgunit=="Staff") {
      setPages(
        [
          { name: "Home", url: "/" },
          { name: "Resources", url: "/getresources" },
          { name: "Posts", url: "/getposts" },
          { name: "Stories", url: "/getstories" },
          { name: "Dashboard", url: "/viewenrollmentcounts" },
        ])
    }
    else if (orgunit=="Board") {
      console.log("Karen-Board");
      setPages(
        [
          { name: "Home", url: "/" },
          { name: "Board", url: "/getboardresources" },
          { name: "Posts", url: "/getposts" },
          { name: "Stories", url: "/getstories" },
          { name: "Dashboard", url: "/viewenrollmentcounts" },
        ])
    }
  }, [orgunit]);


  //console.log(userinfo.name)

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      </Menu>
    );
  };

  const renderMobileMenu = () => {
    return (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem>
          <IconButton color="inherit">
            <Badge badgeContent={11} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <Box sx={styles.root}>
      <AppBar position="static">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/*<IconButton color="inherit" aria-label="Open drawer">
            <MenuIcon />
          </IconButton>*/}

          <Typography sx={{ color: "white"}} variant="h3" noWrap>
            <img src={require('./logo2.png')} width={80} /> CommUnity
          </Typography>
          <Box sx={styles.desktopContainer}>
            {pages.map((page, index) => (
              <Link
                key={index}
                to={page.url}
                style={{
                  padding: "6px 20px",
                  color: "white",
                  textDecoration: "none",
                }}
              >
                {page.name}
              </Link>
            ))}

            <Avatar
              sx={{ width: 40, height: 40 }}
              alt={userinfo.name}
              src={userinfo.picture}
            />
          </Box>
          <Box sx={styles.mobileContainer}>
            <IconButton
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu()}
      {renderMobileMenu()}
    </Box>
  );
};

export default Header;

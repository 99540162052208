import React, { useState, useEffect, useContext } from 'react';
//import { useNavigate } from 'react-router-dom';
//import { FcGoogle } from 'react-icons/fc';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useMutation, useQuery, gql } from "@apollo/client";
import { useSelector } from 'react-redux';
import Header from './Header'
//import { client } from '../client';
import jwt_decode from "jwt-decode";
import { useDispatch } from 'react-redux';
import userSlice, { setuser } from './userSlice';
import Welcome from "./Welcome";
import Getorgunit from "./Getorgunit";

const mutatequery = gql`
  mutation edituser($email: String, $lastlogin: Float, $avatar: String) {
    updateuserbyemail(email: $email, lastlogin: $lastlogin, avatar: $avatar) {
      email
      idnumber
      firstname
      lastname
      orgunit
      lastlogin
      avatar
    }
  }`
;


const GoogleLoginPage = () => {
  //const navigate = useNavigate();
  //let email = "michael.walker@collegeunbound.edu"
  const [ edituser, { data, loading, error } ] = useMutation(mutatequery);
  const clientId = "270790451414-6le54esosos3tl40d7i20g5o312conjr.apps.googleusercontent.com"
  const [success, setSuccess] = useState(false)
  const [doc, setDoc] = useState();
  const [ seluserinfo, setSelUserInfo] = useState(null);
  const [ foundemail, setFoundEmail ] = useState(null);
  const dispatch = useDispatch();
  //const [context, setContext] = useState();

  useEffect(() => {
    //console.log('success', success);
  }, [success])

  const userinfo = useSelector((state) => state.userinfo);

  useEffect(() => {
    //console.log('userinfo', userinfo.name);
    let email = userinfo.email;
    let avatar = userinfo.picture;
    let lastlogin = Date.now();
    setFoundEmail(email);
    //console.log("email=", email, "avatar=", avatar, "lastlogin=", lastlogin);
    edituser({  variables: { email, lastlogin, avatar } });
  }, [userinfo]);

  const responseGoogle = (response) => {
    const userObject = jwt_decode(response.credential);
    sessionStorage.setItem('user', JSON.stringify(userObject));
    //console.log(JSON.stringify(userObject))
    //console.log(Context);

    const { email, hd } = userObject;

    setDoc(userObject);
    //console.log("orgunit=", Getorgunit(email));

    //if (email.search("@collegeunbound.edu") >= 0) {
    if (hd==="collegeunbound.edu") {
      //Getorgunit(email)
      dispatch(setuser(userObject));
      setSuccess(true);
      //sessionStorage.setItem('token', JSON.stringify(doc));
      //console.log(doc);
    } else {
      setSuccess(false);
      <Welcome message="You do not have access to this site." />
      //console.log("You do not have access to this site.")
    }
  }

  return (
    <div className="">
          { !success && (
          <div align='center'>
            <GoogleOAuthProvider clientId={clientId}>
             <GoogleLogin
               onSuccess={responseGoogle}
               useOneTap
               onError={() => {
                 console.log('Login Failed');
               }}
             />
            </GoogleOAuthProvider>
          </div>

        )}
        { foundemail && (
          <Getorgunit email={foundemail} />
        )}
    </div>
  )
}

export default GoogleLoginPage

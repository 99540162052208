import React, { useState, useEffect } from 'react';
import { Typography, Checkbox, FormControl, FormGroup, FormControlLabel, Stack } from "@mui/material";

const Sharewithwhom = (props) => {
  const [ sharearray, setShareArray] = useState([]);

  const [sharewith, setShareWith] = useState({
    students: false,
    faculty: false,
    staff: false,
    board: false,
    world: false,
  });  

  const handleCheckboxChange = (e) => {
    setShareWith({
      ...sharewith,
      [e.target.name]: e.target.checked,
    });
  };

  const { students, faculty, staff, board, world } = sharewith;

  useEffect(() => {
  	let newarray = [];
    if (students) newarray.push("students");
    if (faculty) newarray.push("faculty");
    if (staff) newarray.push("staff");
    if (board) newarray.push("board");
    if (world) newarray.push("world");
    console.log("newarray=", newarray);
    setShareArray(newarray);
  }, [sharewith, students, faculty, staff, board, world]);

  useEffect(() => {
    props.funcsharearray(sharearray);
  }, [sharearray]);

  useEffect(() => {
    console.log("props.setshare=", props.setshare);
    if (props.setshare) {
      if (props.setshare.filter((s) => s==='students').length>0) sharewith.students = true
      if (props.setshare.filter((s) => s==='faculty').length>0) sharewith.faculty = true
      if (props.setshare.filter((s) => s==='staff').length>0) sharewith.staff = true
      if (props.setshare.filter((s) => s==='board').length>0) sharewith.board = true
      if (props.setshare.filter((s) => s==='world').length>0) sharewith.world = true
    }
  }, [props.setshare]);


  return (
    <div align="left">

      <Stack direction="row" sx={{ justifyContent: 'space-evenly', width: '425px', ml: 1, pb: 2}}>
        <Typography sx={{ mt: 1, fontWeight: 500}}>Share with:</Typography>
        <FormControl sx={{ ml: 3 }} component="fieldset" variant="standard">
          <FormGroup row>
            <FormControlLabel control={<Checkbox checked={students} onChange={handleCheckboxChange} name="students" />} label="Students" />
            <FormControlLabel control={<Checkbox checked={faculty} onChange={handleCheckboxChange} name="faculty" />} label="Faculty" />
            <FormControlLabel control={<Checkbox checked={staff} onChange={handleCheckboxChange} name="staff" />} label="Staff" />
            <FormControlLabel control={<Checkbox checked={board} onChange={handleCheckboxChange} name="board" />} label="Board" />
            <FormControlLabel control={<Checkbox checked={world} onChange={handleCheckboxChange} name="world" />} label="World" />
          </FormGroup>
        </FormControl>
      </Stack>

    </div>
  );
}

export default Sharewithwhom;
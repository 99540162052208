import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Slider, Box, Stack } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import { useDispatch } from 'react-redux';
import userOrgunitSlice, { setuserorgunit } from './userOrgunitSlice';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Header from './Header'
import Newposts from './Newposts';
import Getstories from '../stories/Getstories';
import { useNavigate } from 'react-router-dom';

const userquery = gql`
  query Userbyemail($email: String) {
   getuserbyemail(email: $email) {
    firstname
    avatar
    email
    id
    idnumber
    lastlogin
    lastname
    orgunit
  }
 }
`;


const Getorgunit = (props) => {
  let email = props.email;
  const { data, loading, error } = useQuery(userquery, { variables: { email } });
  const [ rows, setRows ] = useState(null);
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ seluserinfo, setSelUserInfo] = useState(null);
  const [ otherinfo, setOtherinfo] = useState(null);
  const [ orgunit, setOrgunit] = useState(null);
  //const [ pathname, setPathname] = useState(null);
  const dispatch = useDispatch();

  //setPathname(sessionStorage.getItem("pathname"));
  const pagedirect = sessionStorage.getItem("pagedirect")
  const navigate = useNavigate();
  //if (pagedirect.length>1) navigate(pagedirect, { replace: true});


  useEffect(() => {
    if (data) {
      setDataLoaded(true);
      setSelUserInfo(data.getuserbyemail);
      console.log(data.getuserbyemail.orgunit);
      //let orgunit = data.getuserbyemail.orgunit;
      setOrgunit(data.getuserbyemail.orgunit);
      setOtherinfo(data.getuserbyemail.idnumber);
      dispatch(setuserorgunit(orgunit));
      console.log("orgunit=", orgunit);
    }
  }, [data]);

  useEffect(() => {
    if (orgunit) {
     console.log("orgunit=", orgunit);
     sessionStorage.setItem('orgunit', orgunit);
    }
  }, [orgunit]);

  useEffect(() => {
    if (otherinfo) {
     console.log("otherinfo=", otherinfo);
     sessionStorage.setItem('otherinfo', otherinfo);
    }
  }, [otherinfo]);

  useEffect(() => {
    console.log("pagedirect=", pagedirect)
    if (pagedirect && pagedirect.length>1) {
      navigate(pagedirect, { replace: true});
      sessionStorage.removeItem("pagedirect");
    }
  }, [pagedirect]);


  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    //console.log(error)
    return <p>Ruh roh! {error.message}</p>;
  }
  
  return (
    <>

    {/*<Newposts otherinfo={otherinfo} />*/}
    </>
  );

}

export default Getorgunit;

import React, { useState, useEffect } from 'react';
import { Stack, Typography, ToggleButton, ToggleButtonGroup, Box } from "@mui/material";
import * as FlexmonsterReact from "react-flexmonster";
import GetUrl from "../components/GetUrl";
import { Getdata } from './Getdata';
import enrollment from "./enrollment.json";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


function Viewenrollmentcounts() {
  const [ gotolink, setGoToLink] = useState("/viewenrollmentcounts");

  const navigate = useNavigate();

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  const userinfo = useSelector((state) => state.userinfo);

  useEffect(() => {
    if (userinfo.length==0) {
      navigate("/", { replace: true})
    }
  }, []);  

  return (
    <>

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '700px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/viewenrollmentcounts">Enrollment</ToggleButton>
          <ToggleButton value="/viewoutcomescounts">Outcomes</ToggleButton>
          <ToggleButton value="/viewenrollmentcrosstabs">Enrollment Crosstabs</ToggleButton>
          <ToggleButton value="/viewoutcomescrosstabs">Outcomes Crosstabs</ToggleButton>
          {/*<ToggleButton value="/viewbillablecredits">Billable Credits</ToggleButton>*/}
        </ToggleButtonGroup>
        <Typography variant="h4" sx={{ ml: 2}}>Enrollment</Typography>        
      </Stack>

      <Box sx={{ fontSize: 11, ml: 2, display:'flex', alignItems:'left' }}>For internal distribution only.</Box>

      <div>
      <FlexmonsterReact.Pivot
       licenseKey="Z70P-XI9F56-045Q66-1E0W0Q-6N5B20-4B6R34-044J6V-2T491Y-2B290W-0C6W02-423X1Z-4I6L53-5W1L"
       height={700}
       width={'1100'}
       align={"center"}
       toolbar={false}
       report={{
         dataSource: {
           data: enrollment
         },
         "slice": {
             "rows": [
                 {
                     "uniqueName": "Semester",
                     "filter": {
                         "exclude": [
                             "semester.[15  summer]",
                             "semester.[16  summer]",
                             "semester.[17  summer]",
                             "semester.[18  summer]",
                             "semester.[19  summer]",
                             "semester.[20  summer]",
                             "semester.[21  summer]",
                             "semester.[22  summer]",
                             "semester.[23  summer]",
                             "semester.[24  summer]"
                         ]
                     }
                 }
             ],
             "columns": [
                 {
                     "uniqueName": "[Measures]"
                 }
             ],
             "measures": [
                 {
                     "uniqueName": "personid",
                     "aggregation": "distinctcount",
                     "caption": "Students"
                 }
             ]
         },
         "options": {
             "viewType": "charts",
             "chart": {
                 "type": "line",
                 "showDataLabels": "true",
                 "title": "Historical Enrollment"
             }
         },
       }}
      />
      </div>

      <div><br/></div>


      <Stack display="flex" direction='row' spacing={5} sx={{ ml: 2, pb: 5 }}>

      <div>

      <FlexmonsterReact.Pivot
       licenseKey="Z70P-XI9F56-045Q66-1E0W0Q-6N5B20-4B6R34-044J6V-2T491Y-2B290W-0C6W02-423X1Z-4I6L53-5W1L"
       width={'450'}
       align={"center"}
       toolbar={false}
       report={{
         dataSource: {
           data: enrollment
         },
         "slice": {
           "reportFilters": [
               {
                   "uniqueName": "Semester",
                   "filter": {
                       "members": [
                           "semester.[24 Fall]"
                       ]
                   }
               }
           ],
           "rows": [
               {
                   "uniqueName": "New_Student"
               }
           ],
           "columns": [
               {
                   "uniqueName": "[Measures]"
               }
           ],
             "measures": [
                 {
                "uniqueName": "personid",
                "aggregation": "percentofcolumn",
                "caption": "Students",
                "format": "-coaqbic3y0c00"
                 }
             ]
         },
         "options": {
             "viewType": "charts",
             "chart": {
                 "type": "pie",
                 "showDataLabels": "true",
                 "title": "New / Continuing"
             }
         },
         "formats": [
             {
                 "name": "-coaqbic3y0c00",
                 "decimalPlaces": 0
             }
         ],
       }}
      />

      </div>

      <div>

      <FlexmonsterReact.Pivot
       licenseKey="Z70P-XI9F56-045Q66-1E0W0Q-6N5B20-4B6R34-044J6V-2T491Y-2B290W-0C6W02-423X1Z-4I6L53-5W1L"
       width={'600'}
       align={"center"}
       toolbar={false}
       report={{
         dataSource: {
           data: enrollment
         },
         "slice": {
           "reportFilters": [
               {
                   "uniqueName": "Semester",
                   "filter": {
                       "members": [
                           "semester.[24 Fall]"
                       ]
                   }
               }
           ],
           "rows": [
               {
                   "uniqueName": "Ethnicity"
               }
           ],
           "columns": [
               {
                   "uniqueName": "[Measures]"
               }
           ],
             "measures": [
                 {
                "uniqueName": "personid",
                "aggregation": "percentofcolumn",
                "caption": "Students",
                "format": "-coaqbic3y0c00"
                 }
             ]
         },
         "options": {
             "viewType": "charts",
             "chart": {
                 "type": "pie",
                 "showDataLabels": "true",
                 "title": "Ethnicity"
             }
         },
         "formats": [
             {
                 "name": "-coaqbic3y0c00",
                 "decimalPlaces": 0
             }
         ],

       }}
      />

      </div>

      </Stack>

      <Stack display="flex" direction='row' spacing={5} sx={{ ml: 2, pb: 5 }}>

      <div>

      <FlexmonsterReact.Pivot
       licenseKey="Z70P-XI9F56-045Q66-1E0W0Q-6N5B20-4B6R34-044J6V-2T491Y-2B290W-0C6W02-423X1Z-4I6L53-5W1L"
       width={'450'}
       align={"center"}
       toolbar={false}
       report={{
         dataSource: {
           data: enrollment
         },
         "slice": {
           "reportFilters": [
               {
                   "uniqueName": "Semester",
                   "filter": {
                       "members": [
                           "semester.[24 Fall]"
                       ]
                   }
               }
           ],
           "rows": [
               {
                   "uniqueName": "Pell_Ever"
               }
           ],
           "columns": [
               {
                   "uniqueName": "[Measures]"
               }
           ],
             "measures": [
                 {
                "uniqueName": "personid",
                "aggregation": "percentofcolumn",
                "caption": "Students",
                "format": "-coaqbic3y0c00"
                 }
             ]
         },
         "options": {
             "viewType": "charts",
             "chart": {
                 "type": "pie",
                 "showDataLabels": "true",
                 "title": "Ever Received Pell"
             }
         },
         "formats": [
             {
                 "name": "-coaqbic3y0c00",
                 "decimalPlaces": 0
             }
         ],
       }}
      />

      </div>

      <div>

      <FlexmonsterReact.Pivot
       licenseKey="Z70P-XI9F56-045Q66-1E0W0Q-6N5B20-4B6R34-044J6V-2T491Y-2B290W-0C6W02-423X1Z-4I6L53-5W1L"
       width={'600'}
       align={"center"}
       toolbar={false}
       report={{
         dataSource: {
           data: enrollment
         },
         "slice": {
           "reportFilters": [
               {
                   "uniqueName": "Semester",
                   "filter": {
                       "members": [
                           "semester.[24 Fall]"
                       ]
                   }
               }
           ],
           "rows": [
               {
                   "uniqueName": "Age_Category"
               }
           ],
           "columns": [
               {
                   "uniqueName": "[Measures]"
               }
           ],
             "measures": [
                 {
                "uniqueName": "personid",
                "aggregation": "percentofcolumn",
                "caption": "Students",
                "format": "-coaqbic3y0c00"
                 }
             ]
         },
         "options": {
             "viewType": "charts",
             "chart": {
                 "type": "pie",
                 "showDataLabels": "true",
                 "title": "Age"
             }
         },
         "formats": [
             {
                 "name": "-coaqbic3y0c00",
                 "decimalPlaces": 0
             }
         ],

       }}
      />

      </div>

      </Stack>

      <Stack display="flex" direction='row' spacing={5} sx={{ ml: 2, pb: 5 }}>

      <div>

      <FlexmonsterReact.Pivot
       licenseKey="Z70P-XI9F56-045Q66-1E0W0Q-6N5B20-4B6R34-044J6V-2T491Y-2B290W-0C6W02-423X1Z-4I6L53-5W1L"
       width={'450'}
       align={"center"}
       toolbar={false}
       report={{
         dataSource: {
           data: enrollment
         },
         "slice": {
           "reportFilters": [
               {
                   "uniqueName": "Semester",
                   "filter": {
                       "members": [
                           "semester.[24 Fall]"
                       ]
                   }
               }
           ],
           "rows": [
               {
                   "uniqueName": "TA_to_BA"
               }
           ],
           "columns": [
               {
                   "uniqueName": "[Measures]"
               }
           ],
             "measures": [
                 {
                "uniqueName": "personid",
                "aggregation": "percentofcolumn",
                "caption": "Students",
                "format": "-coaqbic3y0c00"
                 }
             ]
         },
         "options": {
             "viewType": "charts",
             "chart": {
                 "type": "pie",
                 "showDataLabels": "true",
                 "title": "TA to BA"
             }
         },
         "formats": [
             {
                 "name": "-coaqbic3y0c00",
                 "decimalPlaces": 0
             }
         ],
       }}
      />

      </div>

      <div>

      <FlexmonsterReact.Pivot
       licenseKey="Z70P-XI9F56-045Q66-1E0W0Q-6N5B20-4B6R34-044J6V-2T491Y-2B290W-0C6W02-423X1Z-4I6L53-5W1L"
       width={'450'}
       align={"center"}
       toolbar={false}
       report={{
         dataSource: {
           data: enrollment
         },
         "slice": {
           "reportFilters": [
               {
                   "uniqueName": "Semester",
                   "filter": {
                       "members": [
                           "semester.[24 Fall]"
                       ]
                   }
               }
           ],
           "rows": [
               {
                   "uniqueName": "Gender"
               }
           ],
           "columns": [
               {
                   "uniqueName": "[Measures]"
               }
           ],
             "measures": [
                 {
                "uniqueName": "personid",
                "aggregation": "percentofcolumn",
                "caption": "Students",
                "format": "-coaqbic3y0c00"
                 }
             ]
         },
         "options": {
             "viewType": "charts",
             "chart": {
                 "type": "pie",
                 "showDataLabels": "true",
                 "title": "Gender"
             }
         },
         "formats": [
             {
                 "name": "-coaqbic3y0c00",
                 "decimalPlaces": 0
             }
         ],
       }}
      />

      </div>

      </Stack>

      <Stack display="flex" direction='row' spacing={5} sx={{ ml: 2, pb: 5 }}>

      <div>

      <FlexmonsterReact.Pivot
       licenseKey="Z70P-XI9F56-045Q66-1E0W0Q-6N5B20-4B6R34-044J6V-2T491Y-2B290W-0C6W02-423X1Z-4I6L53-5W1L"
       width={'450'}
       align={"center"}
       toolbar={false}
       report={{
         dataSource: {
           data: enrollment
         },
         "slice": {
           "reportFilters": [
               {
                   "uniqueName": "Semester",
                   "filter": {
                       "members": [
                           "semester.[24 Fall]"
                       ]
                   }
               }
           ],
           "rows": [
               {
                   "uniqueName": "Site"
               }
           ],
           "columns": [
               {
                   "uniqueName": "[Measures]"
               }
           ],
             "measures": [
                 {
                "uniqueName": "personid",
                "aggregation": "percentofcolumn",
                "caption": "Students",
                "format": "-coaqbic3y0c00"
                 }
             ]
         },
         "options": {
             "viewType": "charts",
             "chart": {
                 "type": "pie",
                 "showDataLabels": "true",
                 "title": "Site"
             }
         },
         "formats": [
             {
                 "name": "-coaqbic3y0c00",
                 "decimalPlaces": 0
             }
         ],
       }}
      />

      </div>

      </Stack>



    </>
  );
}

export default Viewenrollmentcounts;
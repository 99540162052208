import React, { useState, useEffect } from 'react';
import { Typography, TextField, Select, MenuItem, Button, Slider, Box, ToggleButton, ToggleButtonGroup, Checkbox, FormGroup, FormControlLabel, Stack, IconButton, Snackbar, Alert } from "@mui/material";
import { useQuery, useMutation, gql } from "@apollo/client";
//import Container from 'react-bootstrap/Container';
import Grid from '@mui/material/Grid';
import Taglist from '../components/Taglist';
import Texteditor from '../components/Texteditor';
import Uploadfp from '../components/Uploadfp';
import Uploadfpone from '../components/Uploadfpone';
import Sharewithwhom from '../components/Sharewithwhom';
import sortArray from 'sort-array';
import GetSignedFileUrl from '../components/GetSignedFileUrl';
import GetImage from "../components/GetImage";
import Fab from '@mui/material/Fab';
import { NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Uploaddel from '../components/Uploaddel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { red, green } from '@mui/material/colors';
import "../components/formatlinks.css";
import {v4 as uuidv4} from 'uuid';

//import Taglist from './Taglist';

const mutatequery = gql`
  mutation editpost($id: ID, $title: String, $posttext: String, $resourcelink: [ResourceLinkInput], $tags: [String], $accessgroups: [String], $blog: String, $position: Int) {
    updatepost(id: $id, title: $title, posttext: $posttext, resourcelink: $resourcelink, tags: $tags, accessgroups: $accessgroups, blog: $blog, position: $position) {
      id
      title
    }
  }`
;

const deletefilequery = gql`
  mutation removepostfile($id: ID, $fileid: String) {
    deletepostfile(id: $id, fileid: $fileid) 
  }`
;

const deleteimagequery = gql`
  mutation removepostimage($id: ID, $fileid: String) {
    deletepostimage(id: $id, fileid: $fileid) 
  }`
;

const addpostfilequery = gql`
  mutation newpostfile($id: ID, $file: [FileInput]) {
    newpostfile(id: $id, file: $file) {
      id
    }
  }`
;

const addpostimagequery = gql`
  mutation addpostimage($id: ID, $postimage: [ImageInput]) {
    newpostimage(id: $id, postimage: $postimage) {
      filename
    }
  }`
;

const blogquery = gql`
  query blog($id: ID) {
    getpost(id: $id) {
    id
    title
    posttext
    author
    authoremail
    otherauthors
    postimage {
      id
      filename
      fileext
      fileuuid
      filedate
      filelink
    }
    file {
      id
      filename
      fileext
      fileuuid
      filedate
      filelink
    }
    resourcelink {
      resourcelinkname
      resourcelinkurl
      resourcelinkid
    }
    tags
    posttype
    blog
    accessgroups
    response {
      id
      responderemail
      responsetext
      responsedate
    }
    position
    createdAt
    archive
  }
 }
`;

const blog2 = gql`
  query blogs {
    getpostscats(accessgroups: ["staff","faculty"], posttype: ["blog"]) {
     id
     title
     posttext
     author
     authoremail
     otherauthors
     postimage {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     resourcelink {
      resourcelinkname
      resourcelinkurl
      resourcelinkid
     }
     tags
     posttype
     accessgroups
     blog
     response {
      responderemail
      responsetext
      responsedate
     }
     position
     createdAt
     archive
    } 
  }
`;

const blognamesquery = gql`
  query blog($idnumber: Int) {
    getuserblogs(idnumber: $idnumber) {
      id
      idnumber
      blogtitle
      blogdescription
    }
  }
`;


function Editblogpost() {
  let id = sessionStorage.getItem("blogtoview");
  let idnumber = sessionStorage.getItem("otherinfo")*1;
  const [ updateid, setUpdateId ] = useState(id)
  const blognamesq = useQuery(blognamesquery, { variables: { idnumber } });
  const { data, loading, error } = useQuery(blogquery, { variables: { id } });
  const [ editpost, { data2, loading2, error2 } ] = useMutation(mutatequery, { refetchQueries: [{ query: blogquery, variables: { id } }],});
  const [ removepostfile, { data3, loading3, error3 } ] = useMutation(deletefilequery, { refetchQueries: [{ query: blogquery }],});
  const [ newpostfile, { data4, loading4, error4 } ] = useMutation(addpostfilequery, { variables: { id } });
  const [ addpostimage, { data5, loading5, error5 } ] = useMutation(addpostimagequery, { variables: { id } });
  const [ removepostimage, { data6, loading6, error6 } ] = useMutation(deleteimagequery, { refetchQueries: [{ query: blogquery }],});
  const [ title, setTitle] = useState("");
  const [ filenames, setFileNames] = useState([]);
  const [ addfiles, setAddFiles] = useState(false);
  //const [ tags, setTags] = useState([]);
  const [ seltags, setSelTags ] = useState(null);
  const [ tagstoset, setTagsToSet ] = useState([]);
  const [ newtag, setNewTag] = useState();
  const [ sharearray, setShareArray] = useState([]);
  const [ posttext, setPostText] = useState();
  const [ position, setPosition] = useState(3);
  const [ post, setPost ] = useState(null);  
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ texttoset, setTextToSet] = useState("");
  const activeStyle = { color: 'green' };
  const [ sharewith, setShareWith ] = useState();
  const [ gotolink, setGoToLink] = useState(null);
  const [ snackbar, setSnackbar] = useState(null);
  const [ postfiles, setPostFiles] = useState([]);
  const [ postimage, setPostImage] = useState([]);
  const [ mainimages, setMainImages] = useState([]);
  const [ showimage, setShowImage] = useState(true);
  const [ blognames, setBlognames ] = useState([]);
  const [ showblognames, setShowblognames] = useState(false);
  const [ blogsel, setBlogsel] = useState("66de072ffcc8fe62b1a9253b");
  const [ rlinks, setRLinks] = useState([]);

  const handleCloseSnackbar = () => setSnackbar(null);

  const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();

  const handleBlogSubmit = (e) => {
    e.preventDefault();
    console.log(e.target["title"].value);
  }

  const handleBlogSelect = (e, value) => {
    //console.log(e.target.value);
    setBlogsel(e.target.value);
  }

  const handleTitleChange = (e, value) => {
    setTitle(e.target.value);
  }

  const handleChangePosition = (event, value) => {
    setPosition(value);
  };

  const getposttext = (data) => {
    //console.log(data);
    //console.log("userinfo=", userinfo);
    setPostText(data);
  }

  const getmainimage = (data) => {
    console.log("Karen--gotimages=", data);
    setMainImages(data);
  }

  const getfilenames = (data) => {
    console.log("Karen--gotfilenames=", data);
    setFileNames(data);
  }

  const gettags = (data) => {
    console.log("tags=", data);
    setSelTags(data);
  }

  const getnewtag = (data) => {
    setNewTag(data);
  }

  const getsharearray = (data) => {
    setShareArray(data);
  }

  const addlink = (event, value) => {
    setRLinks([...rlinks, { resourcelinkid: uuidv4(), resourcelinkname: '',resourcelinkurl:'' }]);
  };

  const deleteresource = (resourcelinkid) => {
    setRLinks(rlinks.filter((rl) => rl.resourcelinkid!==resourcelinkid));
  };  

  const handleUpdateItem = (resourcelinkid, updatedItem) => {
    // Update the item in the array
    setRLinks(prevItems => prevItems.map(item =>
      item.resourcelinkid === resourcelinkid ? updatedItem : item
    ));
  };


  // save all of the information for the post
  const updatepost = () => {
    let accessgroups = sharearray;
    let tags = seltags;
    let file = filenames;
    let blog = blogsel;
    let postimage = mainimages;
    let resourcelink = rlinks;;

    console.log(file.length);
    console.log(postimage.length);

    // must use the same variable names as in the query
    editpost({  variables: { id, title, posttext, resourcelink, tags, accessgroups, blog, position } });
    if (file.length>0) newpostfile({  variables: { id, file } });

    if (postimage.length>0) {
      console.log("postimage=", postimage)
      addpostimage({  variables: { id, postimage } });
    }

    setSnackbar({ children: 'Saving blog post update.', severity: 'success' });
    //navigate("/getblogs", { replace: true})
  }

  useEffect(() => {
    if (data) {
      setPost(data.getpost);
      setDataLoaded(true);
      //console.log(data.getpost);
      //console.log(sessionStorage.getItem("blogtoview"))
    }
  }, [data]);

  useEffect(() => {
    if (post) {
      setTagsToSet(post.tags);
      setTitle(post.title);
      setTextToSet(post.posttext);
      setPosition(post.position);
      setBlogsel(post.blog);
      setShareWith(post.accessgroups);
      setPostFiles(post.file);
      setPostImage(post.postimage);
      console.log("post.file=", post.file);
      console.log("post.postimage=", post.postimage);
      setRLinks(post.resourcelink.map(({ __typename, ...rest }) => rest));

    }
  }, [post]);

  useEffect(() => {
    if (blognamesq.data) {
      let blognamespre = blognamesq.data.getuserblogs;
      setBlognames(sortArray([...blognamespre], { by: ['blogtitle'], order: 'asc'}));
      //setBlognames(sortArray(blognamespre.map((blognamespre) => blognamespre.blogtitle)));
      //setRows(sortArray([...data.getuserblogs], { by: ['createdAt'], order: 'desc'}))
   }
  }, [blognamesq.data]);

  useEffect(() => {
    console.log("blognames=", blognames);
    if (blognames.length>0) {
      setShowblognames(true);
    } 
  }, [blognames]);


  useEffect(() => {
    console.log(sharearray);
  }, [sharearray]);

  useEffect(() => {
    console.log("mainimages=", mainimages);
  }, [mainimages]);

  useEffect(() => {
    console.log("filenames=", filenames);
  }, [filenames]);

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  const delfile = (index, fileid, filelink) => {
    //console.log("id=", id, "fileid=", fileid);
    setPostFiles(postfiles.filter((f) => f.id!==fileid));
    removepostfile({  variables: { id, fileid } });
    Uploaddel(filelink);
  }

  const delimage = (index, fileid, filelink) => {
    //console.log("id=", id, "fileid=", fileid);
    setMainImages(mainimages.filter((f) => f.id!==fileid));
    removepostimage({  variables: { id, fileid } });
    Uploaddel(filelink);
    setShowImage(false);
  }


  return (
    <div align="left">

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '500px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/getposts">View Posts</ToggleButton>
          <ToggleButton value="/addpost">Add Post</ToggleButton>
          <ToggleButton value="/topicalblogs">Blogs</ToggleButton>
        </ToggleButtonGroup>

        <Typography variant="h4">Edit Post</Typography>
      </Stack>

    <Grid container>
      <Grid item style={{ margin: "10px" }}>
          <TextField name="title" value={title} onChange={handleTitleChange} placeholder="Enter a title for your post." variant="standard" size="large" sx={{ m: 1, width: 730 }}></TextField><br/>
          <Texteditor func={getposttext} setthetext={texttoset} height="700px" width="700px" sx={{ m: 2}}/><br/>
      </Grid>
      <Grid item sx={{ m: 5 }}>
          <Taglist functags={gettags} funcnewtag={getnewtag} setthetags={tagstoset} width={"550px"} /><br/><br/>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between' }}>

            <Box sx={{ fontSize: 14, m: 1 }}>{"Position: " + position}</Box>
            <Slider
              aria-label="Temperature"
              defaultValue={3}
              value={position}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={5}
              onChange={handleChangePosition}
              sx={{width: 200}}
            />
            <Box sx={{ fontSize: 11, ml: 6, display:'flex', alignItems:'center' }}>(Posts with lower numbers appear first.)</Box>


          </Stack><br/>

          <Sharewithwhom funcsharearray={getsharearray} setshare={sharewith} />


          {postimage.length>0 ? (

          <Grid container direction="column" align='left' sx={{ mb: 2, ml: 1, mt: 2}}>
            <Grid item>
              <Box>Main Images</Box>
            </Grid>
            {postimage.map((f, index) => (
              <Grid item>
                <Stack spacing={1} direction="row">
                  <IconButton onClick={() => { delimage(index,f.id,f.filelink)}} name={"del" + f.id} key={f.id} sx={{ color: red[500], width: 20, height: 20 }}><DeleteIcon /></IconButton>
                  <GetSignedFileUrl filelink={f.filelink} filename={f.filename} />
                  {post && post.postimage.length>0 && ( <GetImage filelink={f.filelink} filename={f.filename} width="50"/> )}
                </Stack>
              </Grid>
            ))}
          </Grid>
          
          ):(
            <>
            <Box sx={{ ml: 1 }}>Upload Image</Box>
            <Uploadfpone func={getmainimage} />
            <br/>
            </>
          )}

          {post && post.file.length>0 && (

          <Grid container direction="column" align='left' sx={{ mb: 2, ml: 1, mt: 2}}>
            <Grid item>
              <Box>Current Files</Box>
            </Grid>
            {postfiles.map((f, index) => (
              <Grid item>
                <Stack spacing={1} direction="row">
                  <IconButton onClick={() => { delfile(index,f.id,f.filelink)}} name={"del" + f.id} key={f.id} sx={{ color: red[500], width: 20, height: 20 }}><DeleteIcon /></IconButton>
                  <GetSignedFileUrl filelink={f.filelink} filename={f.filename} />
                </Stack>
              </Grid>
            ))}
          </Grid>

          )}


          <Box sx={{ ml: 1, fontWeight: 500 }}>Add Files</Box>
          <Uploadfp func={getfilenames}/>
            <br/>

          <Box sx={{ ml: 1, mt: 2, fontWeight: 500 }}>Add Links</Box>
          <Box sx={{bgcolor: '#f2f0f0'}}>
          { rlinks.map((rl, index) => (
              <>
                 <TextField name={"linkname" + index} value={rl.resourcelinkname} onChange={(e) => handleUpdateItem(rl.resourcelinkid, { ...rl, resourcelinkname: e.target.value })} placeholder="Enter name" variant="standard" sx={{ m: 1, width: 225, fontSize: 10 }}></TextField>
                 <TextField name={"linkurl" + index} value={rl.resourcelinkurl} onChange={(e) => handleUpdateItem(rl.resourcelinkid, { ...rl, resourcelinkurl: e.target.value })} placeholder="Enter link" variant="standard" sx={{ m: 1, width: 275, fontSize: 10 }}></TextField>
                 <IconButton name={"deleteresource" + index} sx={{ color: red[500], width: 20, height: 20, ml: 1, mt: 2, mr: 1 }} onClick={() => deleteresource(rl.resourcelinkid) }><DeleteIcon /></IconButton><br/>
              </>
            ))
          }
          <IconButton sx={{ color: green[500], width: 20, height: 20, ml: 1, mb: 2 }} onClick={addlink}><AddCircleIcon /></IconButton>
            <br/>
          </Box>            


          { showblognames && (

          <>
          <Stack spacing={1} direction="row" sx={{ mt: 5, justifyContent: 'space-between' }}>
          <Box sx={{ ml: 1 }}>Blog: </Box>
          <Select value={blogsel} sx={{ ml: 1, width: 375}} onChange={handleBlogSelect} variant="standard">
            <MenuItem key="blognamesnone" value="66de072ffcc8fe62b1a9253b">None</MenuItem>
            {blognames.map((bn, index) => (
              <MenuItem key={bn.id} value={bn.id}>{bn.blogtitle}</MenuItem>
            ))}
          </Select>
          </Stack><br/>
          </>

          )}          

          <Button onClick={updatepost} variant='contained'>Update Post</Button>


      </Grid>

    </Grid>

    {!!snackbar && (
      <Snackbar
        open
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
        autoHideDuration={3500}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>
    )}


    </div>
  );
}

export default Editblogpost;

import React, { useEffect } from 'react';
import GoogleLoginPage from './GoogleLoginPage';
import { useSelector } from 'react-redux';
import Header from './Header'

const Welcome = (props) => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const userinfo = useSelector((state) => state.userinfo);
  const userorgunit = useSelector((state) => state.userorgunit);
  //console.log("userorgunit=", userorgunit);
  const orgunit = sessionStorage.getItem("orgunit");
  const headershow = sessionStorage.getItem("headershow");
  
  
  console.log("headershow=", headershow);
  //console.log("userinfo=", userinfo);
  console.log("userinfo.length=", userinfo.length);
  const message = props.message;
  //console.log("message=", message);

  useEffect(() => {
    console.log("orgunit=", orgunit);
  }, [orgunit])

  return (
    <>
    </>
  );
}

export default Welcome;

import { createSlice } from '@reduxjs/toolkit'
// Slice
const userOrgunitSlice = createSlice({
  name: 'userorgunit',
  initialState: 'Unknown',
  reducers: {
    setuserorgunit: (state, action) => {
      return action.payload;
    },
  },
});

export const { setuserorgunit } = userOrgunitSlice.actions;

export default userOrgunitSlice.reducer;

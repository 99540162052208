import React, { useState } from 'react';
import AWS from 'aws-sdk';
import { Link } from "@mui/material";

const GetSignedFileUrl = ({filelink, filename}) => {
  const [ signedUrl, setSignedUrl ] = useState(null);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS,
    secretAccessKey: process.env.REACT_APP_SECRET,
    region: process.env.REACT_APP_REGION,
  });


  const s3 = new AWS.S3();

  const params = {Bucket: 'cu1', Key: filelink, Expires: 60*600};
  //const params = {Bucket: 'cu1', Key: filename};
  s3.getSignedUrl('getObject', params, function (err, url) {
      setSignedUrl(url);
  });

  return (
    <Link href={signedUrl} target="_blank" rel="noopener">{filename}</Link>
  );
};

export default GetSignedFileUrl;

import React, { useState, useEffect } from 'react';
import { Typography, Stack, ToggleButton, ToggleButtonGroup, Select, MenuItem, TextField, Button, Box, Pagination, IconButton, FormHelperText } from "@mui/material";
import AWS from 'aws-sdk';
import { useQuery, useMutation, gql } from "@apollo/client";
import parser from 'html-react-parser';
import moment from 'moment';
import Taglistabb from '../components/Taglistabb';
import Grid from '@mui/material/Grid';
import Getuserinfodate from "../components/Getuserinfodate";
import Header from '../components/Header'
import GetImage from "../components/GetImage";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import sortArray from 'sort-array';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { yellow, red } from '@mui/material/colors';
import emailjs from '@emailjs/browser';
import { Getname} from './Utils';

import "../components/formatlinks.css";

const blogquery = gql`
  query blogs {
    getpostscatsdate(accessgroups: ["staff","faculty"], posttype: ["blog","resource","story"], startdate: 1727661536000) {
     id
     title
     posttext
     author
     authoremail
     otherauthors
     postimage {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     tags
     posttype
     blog
     accessgroups
     response {
      responderemail
      responsetext
      responsedate
     }
     position
     createdAt
     archive
    } 
  }
`;

const deletequery = gql`
  mutation removepost($id: ID) {
    deletepost (id: $id)
  }
`;

const archivequery = gql`
  mutation editpostarchive($id: ID, $archive: String) {
    updatepostarchive(id: $id, archive: $archive) {
      id
      archive
    }
  }`
;

const blognamesquery = gql`
  query blog {
    getblogs {
      id
      idnumber
      blogtitle
      blogdescription
    }
  }
`;

function Foremail() {
  const { data, loading, error } = useQuery(blogquery);
  const [ removepost, { data2, loading2, error2 } ] = useMutation(deletequery, { refetchQueries: [{ query: blogquery }],});
  const [ editpostarchive, { data3, loading3, error3 } ] = useMutation(archivequery, { refetchQueries: [{ query: blogquery }],});
  const blognamesq = useQuery(blognamesquery);
  const [ rows, setRows ] = useState(null);
  const [ rowsall, setRowsAll ] = useState([]);
  const [ numrows, setNumRows ] = useState(0);
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ gotolink, setGoToLink] = useState("/getposts");
  const [ seltags, setSelTags ] = useState([]);
  const [ authoremails, setAuthorEmails ] = useState([]);
  const [ authornames, setAuthorNames ] = useState([]);
  const [ selectedauthor, setSelectedAuthor ] = useState("");
  const [ clearthetags, setClearTheTags ] = useState(false);
  const [ tagstoset, setTagsToSet ] = useState([]);
  const [ tagstatus, setTagStatus ] = useState("tagsnotset");
  const [ selectedname, setSelectedName ] = useState("All Authors");
  const [ sortby, setSortBy ] = useState("createdAt");
  const [ raninit, setRanInit ] = useState(false);
  const [ ranfind, setRanFind ] = useState(false);
  const [ blognames, setBlognames ] = useState([]);
  const [ showblognames, setShowblognames] = useState(false);
  const [ blogsel, setBlogsel] = useState("All Blogs");

  const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();
  sessionStorage.removeItem("fromnewpostpage");

  //console.log("seltags=", seltags);

  /**************************** main filters  ****************************/

  // navigate to the page that will show a particular blog post
  // add the id of the blog post to session storage so that the view blog page knows which one
  // also store the current find blog selection for later return
  function viewlink(id, posttype) {
    return '/viewpostdirect/' + id + '/' + posttype[0];
  }

  /******************************** triggers ***************************/
  useEffect(() => {
    if (data) {
      setRows(sortArray([...data.getpostscatsdate], { by: ['createdAt'], order: 'desc'}))
      setRowsAll(sortArray([...data.getpostscatsdate], { by: ['createdAt'], order: 'desc'}))
      setDataLoaded(true);
   }
  }, [data]);


  /**************************************** helper functions ********************************/

  // show only the first 325 characters of a blog post
  const getWordStr = (mytext) => {
    let newvalue = mytext;
    if (newvalue.length>=325) {
      newvalue = newvalue.toString().substring(0,325) + ".....";
    }
    return newvalue;
  }

  const uv = (oldarray) => {
    const uniqueValues = [];
    oldarray.map((item) => {
      if (uniqueValues.indexOf(item) === -1) {
          uniqueValues.push(item)
      }
    });
    return uniqueValues;
  }

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  const handleNameSelect = (e, value) => {
    setSelectedName(e.target.value);
    //setTagsToSet([]);
  }

  const handleBlogSelect = (e, value) => {
    setBlogsel(e.target.value);
    //setTagsToSet([]);
  }

  // get the e-mail address associated with the selected name
  const getemail = () => {
    let email = "";
    let sr = rowsall.filter((row) => row.author===selectedname);
    console.log("sr=", sr);
    email = sr[0].authoremail;
    return email;
  }

  useEffect(() => {
    if (rows) {
      //console.log("rows=", rows);
      let textbody = "<html><head></head><body><div style='width:500px'>";
      //let textbody = "";
      rows.map((post, index) => (
        textbody = textbody + "<p><a href='https://community.collegeunbound.edu" + viewlink(post.id,post.posttype) + "'>" +  post.title + "</a></p>"
        + "<p>" + getWordStr(post.posttext) + "</p><p>by " + post.author + " on " + moment(parseFloat(post.createdAt)).format('MM/DD/YY') + "</p><br/>"
      ));
      textbody = textbody + "</div></body></html>"
      console.log("textbody=", textbody);
      sendmail("abush@collegeunbound.edu","New on the CU Community Site",textbody);
    }
  }, [rows,numrows]);

  const sendmail = (to_email, subject, message) => {
    // initialize using your User ID saved in the .env file
    emailjs.init(process.env.REACT_APP_EMAIL_USER_ID);
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE_ID, // The service ID saved in the .env file
        process.env.REACT_APP_EMAIL_TEMPLATE_ID, // The template ID also saved in the .env file
        // Start of the variables defined in the template earlier
        {
          to_email: to_email,
          subject: subject,
          message: message,
        }
        // End of the variables defined in the template earlier
      )
      .then((response) => {
        // Debug statement on the console to show the function has been executed successfully
        console.log(response);
      })
      .catch((error) => {
        // Debug statement on the console to show the error that occured
        console.log(error);
    });
  }

  return (
    <div align="left">

      { dataloaded && userinfo.email && (


        <Grid container direction="column" align='left' width="575px" sx={{ml: 7}}>

        {rows.map((post, index) => (
          <Grid item key={post.id} align="left">
            <Stack direction="row">
              <Link to={viewlink(post.id,post.posttype)}>{post.title}</Link>
            </Stack>
            <Stack spacing={5} direction="row">
              <Typography key={'posttext' + post.id} sx={{ width: 420 }}>{parser(getWordStr(post.posttext))}</Typography>
              { post.postimage.length>0 && (
              <Box>
                {post.postimage.map((f, index) => (
                  <>
                  <GetImage filelink={f.filelink} filename={f.filename} width="200"/>
                  </>
                ))}
              </Box>
              )}
            </Stack>
            <Getuserinfodate email={post.authoremail} posteddate={moment(parseFloat(post.createdAt)).format('MM/DD/YY')} /><br/><br/>            
          </Grid>
        ))}

        </Grid>

      )}
    
    </div>
  );


}

export default Foremail;
